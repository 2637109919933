// Inspired by: https://github.com/AntonyKidless/rpg/blob/master/generator.js
export function generateRandomPassword(length = 34) {
  var uppercase  = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var lowercase  = 'abcdefghijklmnopqrstuvwxyz';
  var numbers    = '0123456789';
  // var symbols    = '!#$%&\'()*+,-./:;<=>?@^[]^_`{|}~';
  var symbols    = '!$%&()*+,-./:<=>@^[]^_`|';
  var characters = uppercase + lowercase + numbers + symbols;
  var password = '';
  
  for (var index = 0; index < length; index++) {
    var character = Math.floor(Math.random() * characters.length);
    password += characters.substring(character, character + 1);
  }
  
  console.debug(`Generated a new password suggestion with a length of ${length} characters.`);

  return password;
}
