import ClipboardJS from 'clipboard';
import * as utilities from '../utilities';

function getAll(selector) {
  return Array.prototype.slice.call(document.querySelectorAll(selector), 0);
}

function setupBehaviours() {
  // Clipboard.
  new ClipboardJS('.clipboard');
  new ClipboardJS('.clipboard-previous-sibling', {
    text: function(trigger) {
      if (trigger.previousSibling) {
        return trigger.previousElementSibling.textContent.trim();
      } else {
        return '';
      }
    }
  });



  // Password Suggestions (suggest a password on page load if there is no other value).
  var $password_suggestions = getAll('[data-suggest-password]');
  if ($password_suggestions.length > 0) {
    $password_suggestions.forEach(function ($el) {
      if ($el.value.length <= 0) {
        let pw_length = Math.trunc($el.dataset.suggestPassword);
        $el.value = utilities.generateRandomPassword(length = pw_length);
        console.debug(`Suggested a new password for ${$el.id} as it did not contain a value, yet.`);
      }
    })
  }

  // Password Suggestions (suggest a password on button click).
  var $password_suggestion_buttons = getAll('.suggest-password');
  if ($password_suggestion_buttons.length > 0) {
    $password_suggestion_buttons.forEach(function (button) {
      button.addEventListener('click', function() {
        var target = button.dataset.suggestPasswordTarget;
        var length = Math.trunc(button.dataset.suggestPasswordLength);
        var target_element = document.querySelector(target);
        if (target_element) {
          target_element.value = utilities.generateRandomPassword(length = length);
          console.debug(`Suggested a new password for ${target}.`);
        }
      });
    })
  }



  // Auto-focus input fields that require manual entries.
  var $manual_entry_required = getAll('.requires-manual-entry');
  if ($manual_entry_required.length > 0) {
    $manual_entry_required[0].focus();
  }



  // Hamburger Toggling.
  var $burgers = getAll('.burger');

  if ($burgers.length > 0) {
    $burgers.forEach(function ($el) {
      $el.addEventListener('click', function () {
        var target = $el.dataset.target;
        var $target = document.getElementById(target);
        $el.classList.toggle('is-active');
        $target.classList.toggle('is-active');
      });
    });
  }



  // Modal Dialogs.
  var rootEl = document.documentElement;
  var $modals = getAll('.modal');
  var $modalButtons = getAll('.modal-button');
  var $modalCloses = getAll('.modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button');

  if ($modalButtons.length > 0) {
    $modalButtons.forEach(function ($el) {
      $el.addEventListener('click', function () {
        var target = $el.dataset.target;
        openModal(target);
      });
    });
  }

  if ($modalCloses.length > 0) {
    $modalCloses.forEach(function ($el) {
      $el.addEventListener('click', function () {
        closeModals();
      });
    });
  }

  function openModal(target) {
    var $target = document.getElementById(target);
    rootEl.classList.add('is-clipped');
    $target.classList.add('is-active');
  }

  function closeModals() {
    rootEl.classList.remove('is-clipped');
    $modals.forEach(function ($el) {
      $el.classList.remove('is-active');
    });
  }

  document.addEventListener('keydown', function (event) {
    var e = event || window.event;
    if (e.keyCode === 27) { // ESC
      closeModals();
      // closeDropdowns();
    }
  });



  // File Fields (show the current file name).
  var $files = getAll('.file.has-name')
  if ($files.length > 0) {
    $files.forEach(function ($el) {
      var fileEl = $el.querySelector('.file-input')
      if (!fileEl) return;

      fileEl.addEventListener('change', function () {
        var nameEl = $el.querySelector('.file-name')
        if (!nameEl) return;

        if (fileEl.files.count <= 0) {
          nameEl.innerHTML = ''
        } else {
          nameEl.innerHTML = fileEl.files[0].name;
        }
      });
    });
  }
}

// document.addEventListener("DOMContentLoaded", setupBehaviours);
document.addEventListener("turbolinks:load", setupBehaviours);
