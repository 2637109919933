/*!
 * Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
(function () {
  'use strict';

  var _WINDOW = {};
  var _DOCUMENT = {};

  try {
    if (typeof window !== 'undefined') _WINDOW = window;
    if (typeof document !== 'undefined') _DOCUMENT = document;
  } catch (e) {}

  var _ref = _WINDOW.navigator || {},
      _ref$userAgent = _ref.userAgent,
      userAgent = _ref$userAgent === void 0 ? '' : _ref$userAgent;

  var WINDOW = _WINDOW;
  var DOCUMENT = _DOCUMENT;
  var IS_BROWSER = !!WINDOW.document;
  var IS_DOM = !!DOCUMENT.documentElement && !!DOCUMENT.head && typeof DOCUMENT.addEventListener === 'function' && typeof DOCUMENT.createElement === 'function';
  var IS_IE = ~userAgent.indexOf('MSIE') || ~userAgent.indexOf('Trident/');

  var NAMESPACE_IDENTIFIER = '___FONT_AWESOME___';
  var PRODUCTION = function () {
    try {
      return "production" === 'production';
    } catch (e) {
      return false;
    }
  }();

  function bunker(fn) {
    try {
      fn();
    } catch (e) {
      if (!PRODUCTION) {
        throw e;
      }
    }
  }

  function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);

    if (Object.getOwnPropertySymbols) {
      var symbols = Object.getOwnPropertySymbols(object);

      if (enumerableOnly) {
        symbols = symbols.filter(function (sym) {
          return Object.getOwnPropertyDescriptor(object, sym).enumerable;
        });
      }

      keys.push.apply(keys, symbols);
    }

    return keys;
  }

  function _objectSpread2(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};

      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          _defineProperty(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
    }

    return target;
  }

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var w = WINDOW || {};
  if (!w[NAMESPACE_IDENTIFIER]) w[NAMESPACE_IDENTIFIER] = {};
  if (!w[NAMESPACE_IDENTIFIER].styles) w[NAMESPACE_IDENTIFIER].styles = {};
  if (!w[NAMESPACE_IDENTIFIER].hooks) w[NAMESPACE_IDENTIFIER].hooks = {};
  if (!w[NAMESPACE_IDENTIFIER].shims) w[NAMESPACE_IDENTIFIER].shims = [];
  var namespace = w[NAMESPACE_IDENTIFIER];

  function defineIcons(prefix, icons) {
    var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var _params$skipHooks = params.skipHooks,
        skipHooks = _params$skipHooks === void 0 ? false : _params$skipHooks;
    var normalized = Object.keys(icons).reduce(function (acc, iconName) {
      var icon = icons[iconName];
      var expanded = !!icon.icon;

      if (expanded) {
        acc[icon.iconName] = icon.icon;
      } else {
        acc[iconName] = icon;
      }

      return acc;
    }, {});

    if (typeof namespace.hooks.addPack === 'function' && !skipHooks) {
      namespace.hooks.addPack(prefix, normalized);
    } else {
      namespace.styles[prefix] = _objectSpread2(_objectSpread2({}, namespace.styles[prefix] || {}), normalized);
    }
    /**
     * Font Awesome 4 used the prefix of `fa` for all icons. With the introduction
     * of new styles we needed to differentiate between them. Prefix `fa` is now an alias
     * for `fas` so we'll easy the upgrade process for our users by automatically defining
     * this as well.
     */


    if (prefix === 'fas') {
      defineIcons('fa', icons);
    }
  }

  var icons = {
    "dungeon": [512, 512, [], "f6d9", ["M304 189.36V472a8 8 0 0 0 8 8h16a8 8 0 0 0 8-8V219.13a96.32 96.32 0 0 0-32-29.77zm-64-11.74V472a8 8 0 0 0 8 8h16a8 8 0 0 0 8-8V177.62a94.83 94.83 0 0 0-16-1.62 94.83 94.83 0 0 0-16 1.62zm-64 41.51V472a8 8 0 0 0 8 8h16a8 8 0 0 0 8-8V189.36a96.32 96.32 0 0 0-32 29.77z", "M112 288H16a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h96a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16zm16.73-92.68l-82.81-51.76c-8-5-19-2.17-22.93 6.45A254.32 254.32 0 0 0 .54 239.28C0 248.37 7.59 256 16.69 256h97.13c8 0 14.08-6.25 15-14.16a126.36 126.36 0 0 1 6.24-26.94c2.57-7.34.26-15.46-6.33-19.58zM453 93.11a257.4 257.4 0 0 0-71.61-59.89c-8.28-4.68-18.88-.52-22.42 8.31l-36.32 90.8c-2.85 7.12 0 14.88 6.3 19.28a127.56 127.56 0 0 1 16.79 14c5.44 5.41 13.6 6.86 20.11 2.79l82.93-51.83c8.1-4.98 10.32-16.14 4.22-23.46zM496 416h-96a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h96a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16zM153 41.53c-3.53-8.83-14.13-13-22.42-8.31A257.4 257.4 0 0 0 59 93.11c-6.06 7.32-3.85 18.48 4.22 23.52l82.93 51.83c6.51 4.07 14.66 2.62 20.11-2.79a127.56 127.56 0 0 1 16.79-14c6.28-4.41 9.15-12.17 6.3-19.29zM112 416H16a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h96a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16zm384-128h-96a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h96a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16zm-97.82-32h97.13c9.1 0 16.74-7.63 16.15-16.72A254.05 254.05 0 0 0 489 150c-3.94-8.62-14.89-11.47-22.93-6.45l-82.81 51.76c-6.59 4.12-8.9 12.24-6.34 19.58a125.9 125.9 0 0 1 6.24 26.94c.94 7.92 7.06 14.17 15.02 14.17zM319 8a252.3 252.3 0 0 0-126 0c-9.17 2.35-13.91 12.6-10.39 21.39l37.47 104a16 16 0 0 0 15 10.58h41.8A16 16 0 0 0 292 133.42l37.47-104C332.94 20.6 328.2 10.36 319 8z"]],
    "file-certificate": [512, 512, [], "f5f3", ["M512 128v360.12A23.94 23.94 0 0 1 488 512H224v-91.19a61.78 61.78 0 0 0 35.49-41.11c.38-1.42.72-2.72 1-3.94.66-2.56 1.4-5.39 1.89-7 1.14-1.23 3.12-3.24 4.92-5.06l2.91-2.94A62.5 62.5 0 0 0 286 301.38c-.37-1.41-.72-2.71-1-3.92-.69-2.61-1.46-5.5-1.84-7.16.38-1.68 1.16-4.6 1.86-7.25.31-1.18.65-2.45 1-3.83a62.45 62.45 0 0 0-15.63-59.28l-2.76-2.81c-1.85-1.88-3.9-3.95-5.05-5.2-.49-1.64-1.23-4.51-1.91-7.1q-.47-1.8-1-3.9a61.69 61.69 0 0 0-43.13-43.7h-.11l-3.75-1c-2.44-.66-5.13-1.39-6.75-1.88-1.23-1.18-3.22-3.21-5-5.05-.84-.86-1.73-1.78-2.71-2.77a60.88 60.88 0 0 0-59.47-16.31h-.05l-3.77 1c-2.4.66-5 1.39-6.69 1.79V23.88A23.94 23.94 0 0 1 152 0h232v112a16 16 0 0 0 16 16z", "M505 105L407.1 7a24 24 0 0 0-17-7H384v112a16 16 0 0 0 16 16h112v-6.1a23.9 23.9 0 0 0-7-16.9zM255 271.09a30.14 30.14 0 0 0-7.58-28.79c-14.86-15.12-13.42-12.61-18.86-33.3a29.57 29.57 0 0 0-20.71-21c-20.28-5.53-17.84-4.1-32.69-19.21a28.92 28.92 0 0 0-28.28-7.79c-20.32 5.54-17.46 5.53-37.75 0a28.94 28.94 0 0 0-28.28 7.71c-14.91 15.18-12.5 13.7-32.68 19.21A29.53 29.53 0 0 0 27.46 209c-5.46 20.74-4 18.13-18.87 33.27A30.15 30.15 0 0 0 1 271.09c5.45 20.71 5.42 17.79 0 38.41a30.12 30.12 0 0 0 7.58 28.78c14.86 15.11 13.42 12.61 18.88 33.27a29.52 29.52 0 0 0 20.71 21.07c14.3 3.9 11.52 3 15.83 5V512l64-32 64 32V397.63c4.31-2 1.52-1.1 15.84-5a29.53 29.53 0 0 0 20.7-21.07c5.47-20.74 4-18.13 18.88-33.27a30.12 30.12 0 0 0 7.58-28.78c-5.43-20.65-5.44-17.74 0-38.42zM128 352a64 64 0 1 1 64-64 64 64 0 0 1-64 64z"]]
  };

  bunker(function () {
    defineIcons('fad', icons);
  });

}());
